<template>
  <div class="vx-row">
    <div class="vx-col w-1/5">
      {{ $t("Tienda") }}:
      <v-select
        :options="stores"
        v-model="filters.storeName"
        label="name"
        :reduce="(store) => store.name"
        class="mt-5"
      >
        <template slot="option" slot-scope="store">
          {{ store.name }}
        </template>

        <template slot="selected-option" slot-scope="store">
          {{ store.name }}
        </template>
      </v-select>
    </div>

    <div class="vx-col w-1/5">
      {{ $t("Precio Promoción") }} :
      <vs-input v-model="filters.price" class="mt-5" />
    </div>

    <div class="vx-col w-3/5">
      <vs-button
        color="warning"
        class="mt-8 rounded-full float-right"
        @click="applyFilters()"
      >
        <feather-icon
          icon="RefreshCcwIcon"
          :class="
            searching ? 'align-middle animate-spin mr-2' : 'align-middle mr-2'
          "
        />
        {{ $t("Aplicar") }}
      </vs-button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  data() {
    return {
      filters: {
        storeName: "",
        price: null,
      },
      searching: false,
    };
  },

  created() {
    this.$vs.loading();
    this.$store
      .dispatch("stores/getAllStores")
      .then(() => this.$vs.loading.close());
  },

  methods: {
    applyFilters() {
      this.$vs.loading();
      this.searching = true;

      const newFilters = {
        filter: {
          shop: this.filters.storeName ? this.filters.storeName : "",
          price: parseFloat(!this.filters.price ? 0 : this.filters.price),
        },
      };

      this.$store
        .dispatch("inventories/filterInventories", newFilters)
        .then(() => {
          this.$vs.loading.close();
          this.searching = false;
        });
    },
  },

  computed: {
    stores() {
      return this.$store.state.stores.stores;
    },
  },

  components: {
    "v-select": vSelect,
  },
};
</script>
