<template>
  <div>
    <vx-card>
      <div class="mb-4 flex justify-between align-middle">
        <h2 class="w-full text-info">{{ $t("Promociones actuales") }}</h2>

        <div class="flex flex-col justify-end items-end w-full">
          <vs-button
            @click="fetchPromotions()"
            color="transparent"
            text-color="gray"
          >
            <feather-icon
              icon="RefreshCwIcon"
              :class="{ 'animate-spin': loadingPromotions }"
            ></feather-icon>
          </vs-button>
          <!-- eslint-disable -->
          <vs-select
            :label="this.$t('items por página')"
            v-model="pagination.page_size"
            class="w-32"
          >
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in maxItemsVals"
            />
          </vs-select>
        </div>
      </div>

      <vs-table
        class="promotions-table"
        :data="promotions"
        :noDataText="$t('Datos no disponibles')"
      >
        <template slot="thead">
          <vs-th>{{ $t("Código") }}</vs-th>
          <vs-th>{{ $t("Nombre") }}</vs-th>
          <vs-th>{{ $t("Tipo promoción") }}</vs-th>
          <vs-th>{{ $t("Fecha inicio") }}</vs-th>
          <vs-th>{{ $t("Fecha fin") }}</vs-th>
          <vs-th>{{ $t("Estado") }}</vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
            <!-- Codigo -->
            <vs-td :data="data[indextr].promotion_code">
              <p class="font-bold">{{ data[indextr].promotion_code }}</p>
            </vs-td>

            <!-- nombre -->
            <vs-td :data="data[indextr].promotion_name">
              {{ data[indextr].promotion_name }}
            </vs-td>

            <!-- tipo -->
            <vs-td :data="data[indextr].name_type_promotions">
              {{ data[indextr].name_type_promotions }}
            </vs-td>

            <!-- Fecha inicio  -->
            <vs-td :data="data[indextr].date_init">
              {{ data[indextr].date_init }}
            </vs-td>

            <!-- Fecha fin -->
            <vs-td :data="data[indextr].date_end">
              {{ data[indextr].date_end }}
            </vs-td>

            <!-- tienda -->
            <vs-td :data="data[indextr].status">
              <div
                v-if="data[indextr].status"
                style="background: #841910; color: #ffffff"
                class="w-24 p-1 mb-1 rounded-lg text-center"
              >
                {{ $t("Activo") }}
              </div>
              <div
                v-else
                style="background: #dee2e5; color: black"
                class="w-24 p-1 mb-1 rounded-lg text-center"
              >
                {{ $t("Inactivo") }}
              </div>
            </vs-td>

            <!-- editar, eliminar, guardar -->
            <vs-td :data="data[indextr].key">
              <div class="py-2 flex items-center justify-center gap-8">
                <!-- detalles -->
                <span
                  @click="
                    $router.push({
                      path: `detail/${data[indextr].key}`,
                      append: true,
                    })
                  "
                >
                  <img
                    :src="require(`@/assets/icons/EyeBlue.svg`)"
                    class="w-6"
                  />
                </span>
                <!-- editar -->
                <span
                  class="w-5"
                  @click="
                    $router.push({
                      path: `edit/${data[indextr].key}`,
                      append: true,
                    })
                  "
                >
                  <img
                    :src="require(`@/assets/icons/EditBlueIcon.svg`)"
                    class="w-4"
                  />
                </span>
                <!-- eliminar -->
                <vs-switch
                  v-model="data[indextr].status"
                  @click="deletePromotion(data[indextr].key)"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-pagination
        :total="pagination.total_pages"
        v-model="pagination.page"
        class="mt-5"
      ></vs-pagination>
    </vx-card>
  </div>
</template>

<script>
import http from "@/http/promotions-http-client.js";

export default {
  name: "PromotionPage",
  data() {
    return {
      promotions: [],
      loadingPromotions: true,
      selectedPromotion: {},
      showDeleteModal: false,
      loading: false,
      pagination: { page: 1, page_size: 20, total_pages: 1 },
      maxItemsVals: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    };
  },

  async created() {
    await this.fetchPromotions();
  },

  watch: {
    "pagination.page"() {
      this.fetchPromotions();
    },
    "pagination.page_size"() {
      this.fetchPromotions(true);
    },
  },

  methods: {
    async fetchPromotions(resetPagination) {
      let paginationConfig = { ...this.pagination };
      if (resetPagination) {
        paginationConfig.page = 1;
      }
      const params = new URLSearchParams(paginationConfig).toString();

      this.loadingPromotions = true;
      const response = await http.get(`api/CustonPromotions?${params}`);
      this.loadingPromotions = false;
      if (response.status !== 200) return;
      const { result, pagination } = response.data;
      this.promotions = result;
      this.pagination = {
        ...this.pagination,
        page: pagination.current,
        total_pages: pagination.total_pages,
      };
    },
    async deletePromotion(promotionId) {
      if (!promotionId) return;
      const response = await http.delete(`api/CustonPromotions/${promotionId}`);
      if (response.status !== 201) return;
      this.fetchPromotions();
    },
  },
};
</script>

<style>
.promotions-table .vs-table--thead {
  border-bottom: 1px solid lightgray;
}
.vs-dialog-header {
  display: none !important;
}
</style>
