<template>
  <div>
    <!-- filtros de busqueda -->
    <HeaderPromotion class="mt-8" />
    <!-- listado de promociones -->
    <PromotionsList class="mt-10" />
  </div>
</template>

<script>
import HeaderPromotion from "./Header.vue";
import PromotionsList from "./PromotionsList.vue";

export default {
  name: "PromotionsListPAge",
  components: {
    HeaderPromotion,
    PromotionsList,
  },
  created() {
    this.$store.dispatch("updatePageTitle", {
      title: this.$t("Lista de Promociones"),
      subtitle: this.$t("Agrega y Administra tus promociones"),
    });
  },
};
</script>
